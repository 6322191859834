import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../shared/services/user.service';
//import { PreLaunchServiceService } from '../../shared/services/PreLaunchService/pre-launch-service.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    registerForm: FormGroup;
    verifacationForm: FormGroup;
    forgotPassword: FormGroup;
    ConfirmforgotPassword: FormGroup;
    registerEmail: any = "";
    loginEmail: any = "";
    verificationEmail: any = "";
    forgotPasswordEmail: any = "";
    forgotPasswordEmailConfirm: any = "";
    submitedRegisteration = true;
    confirmationEmail: any = "";
    confirmationPassword: any = "";
    submitted = false;
    submittedVerify = false;
    forgotPasswordConfirm = false;
    submittedForgotPassword = false;
    public listing: any = [];
    public loading = false;
	//today: any = Date.now();
	private today;
    constructor( private route: ActivatedRoute, private toastr: ToastrService, private router: Router, private userService: UserService, private formBuilder: FormBuilder) {
    }

  ngOnInit() {
      console.log('login');
      let email = this.route.snapshot.queryParams["email"];
      this.registerEmail = email;
      this.loginEmail = email;
      //let verification = this.route.snapshot.queryParams["verification"];
      //this.verificationEmail = verification;
      //if (this.verificationEmail) {

      //    this.submitedRegisteration = false;
      //}
      let forgotPassword = this.route.snapshot.queryParams["forgotPassword"];
      this.forgotPasswordEmail = forgotPassword;
      if (this.forgotPasswordEmail) {
          this.submitedRegisteration = false;
      }
      this.loginForm = this.formBuilder.group({
          email: ['', Validators.required],
          password: ['', Validators.required],
      });
	  this.registerForm = this.formBuilder.group({
          email: [this.registerEmail, Validators.required],
          password: ['', Validators.required],
		  password2: ['', Validators.required],
      });
      this.verifacationForm = this.formBuilder.group({
          email: [this.registerEmail, Validators.required],
          code: ['', Validators.required],
      });
      this.forgotPassword = this.formBuilder.group({
          email: [this.registerEmail, Validators.required],
      });

      this.ConfirmforgotPassword = this.formBuilder.group({
          email: [this.forgotPasswordEmailConfirm, Validators.required],
          code: ['', Validators.required],
          password: ['', Validators.required],
      });
    }

    get f() { return this.loginForm.controls; }

  onLoginSubmit() {
	  this.loading = true;
      let formValue: any = this.loginForm.value;
      this.userService.loginUser(formValue).subscribe((data) => {
          console.log('login data of logged in user', data);
          console.log('checking logged in user address', data.address);
          //if (data.status.userCompany === 'None') {
          //    this.toastr.error(`There is no Company User`);
          //    this.loading = false;
          //}
          //else {
			  //let username: string = data.status.firstName + " " + data.status.lastName;
          let username: string = data.name;
			  localStorage.setItem('email', data.email);
          localStorage.setItem('username', username);
          localStorage.setItem('profileImage', data.profileImage);
			  //localStorage.setItem('jobTitle', data.status.jobTitle);
			  //localStorage.setItem('userCompany', JSON.stringify(data.status.userCompany));
			  //localStorage.setItem('token', data.status.token);
			  //localStorage.setItem('userId', data.status.userId);
     //         localStorage.setItem('role', data.status.role);
     //         localStorage.setItem('mobile', data.status.mobile);
     //         localStorage.setItem('Street', data.status.address.Street);
     //         localStorage.setItem('City', data.status.address.City);
     //         localStorage.setItem('ZipPostal', data.status.address.ZipPostal);
     //         localStorage.setItem('CountryRegion', data.status.address.CountryRegion);

			  this.router.navigate(["/dashboard"]);
			  /*this.preLaunchServiceService.getLaunchTime(data.status.userCompany.companyId).subscribe((data) => {
				 // console.log(data.date);
				const launchDate: any = Date.parse(data.date);
				//console.log("oldDate: "+launchDate);
				this.today =  new Date(); 
				const currentDate: any = Date.parse(this.today);
				//console.log("currentDate: "+currentDate);
				if(currentDate >= launchDate){
					localStorage.setItem('Dashboard', 'Dashboard');
					this.router.navigate(["/pages/companyFlow/dashboard"]);
				}else{
					localStorage.setItem('Dashboard', '');
					this.router.navigate(["/pages/pre-launch/landing"]);
				}
			 }, (error) => {
				this.toastr.error(error.error.error, `Login failed:`);
			 });*/
          //}
      }, (error) => {
         this.loading = false;
        this.toastr.error(error.error.error, `Login Failed: Invalid Username/Password`);
      });
      return false;
  }

  get r() { return this.registerForm.controls; }
  onRegisterSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      if (this.registerForm.invalid) {
          return;
      }
	  let formValue: any = this.registerForm.value;
	  this.loading = true;
      this.userService.signUpUser(formValue).subscribe((data) => {
          this.confirmationEmail = formValue.email;
          this.confirmationPassword = formValue.password;
          this.verifacationForm.controls['email'].setValue(formValue.email);
          this.forgotPassword.controls['email'].setValue(formValue.email);
		  this.loading = false;
          this.toastr.success(data.status, `Success`);
          this.submitedRegisteration = false;
	  }, (error) => {
         this.loading = false;
        this.toastr.error(error.error.error, `Registration Failed`);
      });
      return false;
  }
    get c() { return this.verifacationForm.controls; }

    onVerifacationrSubmit() {
        this.submittedVerify = true;
        let formValue: any = this.verifacationForm.value;
        if (!formValue.code) {
            return;
        }
        
        this.loading = true;
        this.userService.ConfirmationUser(formValue).subscribe((data) => {
            const userData: any = { email: this.confirmationEmail, password: this.confirmationPassword }
            this.userService.loginUser(userData).subscribe((data) => {
                this.loading = false;
                if (data.status.userCompany === 'None') {
                    this.toastr.error(`There is no Company User`);
                }
                else {
                    let username: string = data.status.firstName + " " + data.status.lastName;
                    localStorage.setItem('email', data.status.email);
                    localStorage.setItem('username', username);
                    localStorage.setItem('jobTitle', data.status.jobTitle);
                    localStorage.setItem('userCompany', JSON.stringify(data.status.userCompany));
                    localStorage.setItem('token', data.status.token);
                    localStorage.setItem('userId', data.status.userId);
                    localStorage.setItem('role', data.status.role);
                    this.router.navigate(["/pages/pre-launch/landing"]);
                }
            }, (error) => {
                this.loading = false;
                this.toastr.error(error.error.error, `Verification Failed`);
            });
        }, (error) => {
            this.loading = false;
            this.toastr.error(error.error.error, `Verification Failed`);
        });
        return false;
    }

    onClickForgotPassword() {
        console.log('check forgot password', this.loginForm.value);
        let formValue: any = this.loginForm.value;
        if (formValue.email === '') {
            this.toastr.error('Please Enter Your Email Address');
        }
        else {
            this.forgotPasswordEmail = formValue.email;
        }  
    }

    onSendVerificationCodeSubmit() {
        this.loading = true;
        let formValue: any = this.loginForm.value;
        const userData: any = { email: formValue.email}
        this.userService.SendVerificationCodeUser(userData).subscribe((data) => {
            this.loading = false;
            this.forgotPasswordEmail = formValue.email;
            this.forgotPasswordConfirm = true;
            this.forgotPasswordEmail = false;
            this.forgotPasswordEmailConfirm = formValue.email;

        }, (error) => {
            this.loading = false;
            this.toastr.error(error.error.error, `Verification Failed`);
        });
        return false;
    }

    onConfirmforgotPasswordSubmit() {
        this.loading = true;
        let formValue: any = this.ConfirmforgotPassword.value;
        const userData: any = { email: this.forgotPasswordEmailConfirm, verificationCode: formValue.code, newPassword: formValue.password }
        this.userService.ConfirmPasswordUser(userData).subscribe((data) => {
            this.loading = false;
            const userDatanew: any = { email: userData.email, password: formValue.password }
            console.log('user data new',userDatanew);
            this.userService.loginUser(userDatanew).subscribe((data) => {
                this.loading = false;
                if (data.status.userCompany === 'None') {
                    this.toastr.error(`There is no Company User`);
                }
                else {
                    let username: string = data.status.firstName + " " + data.status.lastName;
                    localStorage.setItem('email', data.status.email);
                    localStorage.setItem('username', username);
                    localStorage.setItem('jobTitle', data.status.jobTitle);
                    localStorage.setItem('userCompany', JSON.stringify(data.status.userCompany));
                    localStorage.setItem('token', data.status.token);
                    localStorage.setItem('userId', data.status.userId);
                    localStorage.setItem('role', data.status.role);
                    this.router.navigate(["/pages/pre-launch/landing"]);
                }
            }, (error) => {
                this.loading = false;
                this.toastr.error(error.error.error, `Password has not been reset, please try again`);
            });

        }, (error) => {
            this.loading = false;
            this.toastr.error(error.error.error, `Password has not been reset, please try again`);
        });
        return false;
    }
}
