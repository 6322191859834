import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { TopNevComponent } from './pages/common/top-nev/top-nev.component';
import { LeftNevComponent } from './pages/common/left-nev/left-nev.component';
import { BusinessTypeComponent } from './pages/business-type/business-type.component';
import { AdminProfileComponent } from './pages/admin-profile/admin-profile.component';
import { BasicEntityInformationComponent } from './pages/basic-entity-information/basic-entity-information.component';
import { LaunchDateComponent } from './pages/launch-date/launch-date.component';


const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'top-nev', component: TopNevComponent },
  { path: 'left-nev', component: LeftNevComponent },
  { path: 'pages/business-type', component: BusinessTypeComponent },
  { path: 'pages/admin-profile', component: AdminProfileComponent },
  { path: 'pages/basic-entity-information', component: BasicEntityInformationComponent },
  { path: 'pages/launch-date', component: LaunchDateComponent },
  

];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class RoutingModule { }
