import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ApiModel} from '../models/Api.model';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import { of } from 'rxjs/observable/of';
import { map } from 'rxjs/operators';
import {catchError, tap} from 'rxjs/operators';
import { User } from '../models/User.model';
import {ToastrService} from 'ngx-toastr';
//import {   NgxSpinnerService } from 'ngx-spinner';
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
  })
  };
@Injectable()
export class UserService {
    apiModel: ApiModel = new ApiModel();
    constructor(public http: HttpClient,private router: Router,private toastr: ToastrService) {
    console.log("User service initiated");
  }

  loginUser (user: User) {
      return this.http.post(this.apiModel.baseUrlBackOffoice + "login", user).pipe(map((res: any) => {
		  console.log('res', res);
		  return res;
	  }));
  }
  signUpUser (user: User) {
	  return this.http.post(this.apiModel.userUrl + "signup", user).pipe(map((res: any) => {
		  console.log('res', res);
		  return res;
	  }));
    }

    ConfirmationUser(obj) {
        return this.http.post(this.apiModel.userUrl + "confirm", obj).pipe(map((res: any) => {
            console.log('res', res);
            return res;
        }));
    }

    SendVerificationCodeUser(email) {
        return this.http.post(this.apiModel.userUrl + "forgot_password", email).pipe(map((res: any) => {
            console.log('res', res);
            return res;
        }));
    }
    ConfirmPasswordUser(obj) {
        return this.http.post(this.apiModel.userUrl + "reset_password", obj).pipe(map((res: any) => {
            console.log('res', res);
            return res;
        }));
    }
	getNotificationCount(userId){
		return this.http.get(this.apiModel.userUrl + 'get_notification_count/' + userId ).pipe(map((res: any) => {
            console.log('notification count: ', res);
            return res;
        }));
	}
	getNotifications(userId){
		return this.http.get(this.apiModel.userUrl + 'get_notifications/' + userId ).pipe(map((res: any) => {
            console.log('notifications: ', res);
            return res;
        }));
	}
	getNotificationView(notificationId, userId){
		return this.http.get(this.apiModel.userUrl + 'get_request_details/' + notificationId+'/meeting/'+userId ).pipe(map((res: any) => {
            console.log('notifications: ', res);
            return res;
			//http://localhost:5000/get_request_details/13dda9c0-b1ae-11e8-b464-e91ce5ad40b1/meeting/40818290-42ea-11e8-ac78-255c14b54806
        }));
	}

      getUserById(userId){
          return this.http.get(this.apiModel.userUrl + "/get_profile/" + userId).pipe(map((res: any) => {
              console.log('res', res);
              return res;
          }));
    }

    getCompanies(){
        return this.http.post(this.apiModel.baseUrlBackOffoice + 'companies-listing-and-search', '').pipe(map((res: any) => {
		  console.log('res', res);
		  //http://localhost:8000/companies-listing-and-search
		  return res;
	  }));
    }

    getFilterCompanies(obj) {
        return this.http.post(this.apiModel.baseUrlBackOffoice + 'companies-listing-and-search', obj).pipe(map((res: any) => {
            console.log('res', res);
            //http://localhost:8000/companies-listing-and-search
            return res;
        }));
    }
	launchDateService(){
        return this.http.post(this.apiModel.baseUrlBackOffoice + 'update_launch_date', '').pipe(map((res: any) => {
		  console.log('res', res);
		  //http://localhost:8000/companies-listing-and-search
		  return res;
	  }));
    }

	//check keywords 
	keyWordService(obj) {
        return this.http.post(this.apiModel.baseUrlElasticSearch + "keywordService", obj).pipe(map((res: any) => {
            console.log('res', res);
            return res;
        }));
    }
    //Get Taxonomy list
    getTaxonomy() {
        return this.http.get(this.apiModel.companyUrl + 'get_values_list/taxonomy').pipe(map((res: any) => {
            return res;
        }));
    }

    //Get Years
    getYears() {
        return this.http.get(this.apiModel.companyUrl + 'get_values_list/years').pipe(map((res: any) => {
            return res;
        }));
    }

    //Get country list
    getCountry() {
        return this.http.get(this.apiModel.companyUrl + 'get_values_list/country').pipe(map((res: any) => {
            return res;
        }));
    }

    //Get states list
    getStates(selectedCountry) {
        return this.http.get(this.apiModel.companyUrl + 'get_values_list/states?country='+selectedCountry).pipe(map((res: any) => {
            return res;
        }));
    }
	//check business type
	getBusinessType() {
        return this.http.get(this.apiModel.companyUrl + "getBuissnessTypes").pipe(map((res: any) => {
            console.log('res', res);
            return res;
        }));
    }
	//get sic code
	getSicCode(obj) {
        return this.http.post(this.apiModel.companyUrl + "get_taxonomy",obj).pipe(map((res: any) => {
            console.log('res', res);
            return res;
        }));
    }
	//businessTypeSubmit  https://6x1rft6wre.execute-api.us-east-1.amazonaws.com/Prod/push_company_to_queue
	businessTypeSubmit(obj) {
        return this.http.post(this.apiModel.baseUrlBackOffoice + "push_company_to_queue", obj).pipe(map((res: any) => {
            console.log('res', res);
            return res;
        }));
    }
	
  public handleError<T>(operation = 'operation', result?: T) {

    return (error: any): Observable<T> => {
      this.toastr.error(error.error.error, `${operation} failed:`);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
