import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from '../../../shared/services/user.service';
import Swal from 'sweetalert2';
import * as $ from 'jquery';

@Component({
  selector: 'app-other-office-location',
  templateUrl: './other-office-location.component.html',
  styleUrls: ['./other-office-location.component.css']
})
export class OtherOfficeLocationComponent implements OnInit {
    _ref: any;
    data: any;
    count: any;
    public countryListing: any = [];
    public statesListing: any = [];
    public objForLocations: any = [];
    public selectedCountry: any = '';
    public selectedState: any = '';
    public selectedCity: any = '';
    public selectedStreet: any = '';
	public selectedId: any = '';
    objectKeys = Object.keys;
    locationValues: any = [];
    saveDataNew: any = [];
	public updateLocationVariable: any = [];
    constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private userService: UserService, private router: Router) { }

    ngOnInit() {
		//console.log(localStorage.getItem('locationVariable'));
		//console.log(this._ref);
		this.userService.getCountry().subscribe((data) => {
            this.countryListing = data;
        }, (error) => {
            console.log("err", error);
        }); 
		
		if(this._ref.instance.data){
			if(!localStorage.getItem('locationVariable')){
				let saveData = [];
				saveData.push(this._ref.instance.data);
				localStorage.setItem('locationVariable', JSON.stringify(saveData)); 
			}else{
				let saveData: any = JSON.parse(localStorage.getItem('locationVariable'));
				saveData.push(this._ref.instance.data);
				localStorage.setItem('locationVariable', JSON.stringify(saveData)); 
			}
			this.selectedCountry = this._ref.instance.data.Country;
			this.selectedState = this._ref.instance.data.State;
			this.selectedCity = this._ref.instance.data.City;
			this.selectedStreet = this._ref.instance.data.Street;
			this.selectedId = this._ref.instance.data.id;
			this.userService.getStates(this.selectedCountry).subscribe((data) => {
				if (JSON.stringify(data) === '{}') {
					Swal("", "States Not Found!", "error")
				}
				this.statesListing = data;
				//console.log('checking states', data);
			}, (error) => {
				console.log("err", error);
			});
		}else{
			if(localStorage.getItem('locationVariable')){
				let saveData: any = JSON.parse(localStorage.getItem('locationVariable'));
			}else{
                this.saveDataNew = [];
			}
			const data: any = {}
			data['id'] = this._ref.instance.count;
			data['Country'] = "";
			data['State'] = "";
			data['City'] = "";
			data['Street'] = "";
			this.selectedId = this._ref.instance.count;
            this.saveDataNew.push(data);
            localStorage.setItem('locationVariable', JSON.stringify(this.saveDataNew)); 
		}
    }

    onCountryChange(countryValue, id) {
		console.log(id);
		this.userService.getStates(countryValue).subscribe((data) => {
			if (JSON.stringify(data) === '{}') {
				Swal("", "States Not Found!", "error")
			}
			this.statesListing = data;
		}, (error) => {
			console.log("err", error);
		}); 
		let saveData: any = JSON.parse(localStorage.getItem('locationVariable'));
		saveData.forEach(eachObj => {
			if(eachObj.id == id){
				console.log(eachObj.id);
				eachObj.Country = countryValue;
				eachObj.State = "";
			}
		});
		localStorage.setItem('locationVariable', JSON.stringify(saveData));
		console.log(saveData);
    }

    onStateChange(stateValue, id) {
        let saveData: any = JSON.parse(localStorage.getItem('locationVariable'));
		saveData.forEach(eachObj => {
			if(eachObj.id == id){
				console.log(eachObj.id);
				eachObj.State = stateValue;
			}
		});
		localStorage.setItem('locationVariable', JSON.stringify(saveData));
		console.log(saveData);
    }

    onChangeCity(id) {
        let selectedCity: any = (<HTMLInputElement>document.getElementById('city-'+id)).value;
		let saveData: any = JSON.parse(localStorage.getItem('locationVariable'));
		saveData.forEach(eachObj => {
			if(eachObj.id == id){
				console.log(eachObj.id);
				eachObj.City = selectedCity;
			}
		});
		localStorage.setItem('locationVariable', JSON.stringify(saveData));
		console.log(saveData);
    }
    
    onChangeStreet(id) {
        let selectedStreet: any = (<HTMLInputElement>document.getElementById('street-'+id)).value;
        let saveData: any = JSON.parse(localStorage.getItem('locationVariable'));
		saveData.forEach(eachObj => {
			if(eachObj.id == id){
				console.log(eachObj.id);
				eachObj.Street = selectedStreet;
			}
		});
		localStorage.setItem('locationVariable', JSON.stringify(saveData));
		console.log(saveData);
    }
	removeObject(id){
		let saveData: any = JSON.parse(localStorage.getItem('locationVariable'));
		const newData = saveData.filter(obj => obj.id!== id);
		localStorage.setItem('locationVariable', JSON.stringify(newData));
		console.log(newData);
		this._ref.destroy();
	}
}
