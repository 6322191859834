import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-left-nev',
  templateUrl: './left-nev.component.html',
  styleUrls: ['./left-nev.component.css']
})
export class LeftNevComponent implements OnInit {
    public isShow = true;

  constructor() { }

  ngOnInit() {
  }
    myFunction() {
        
        var x = document.getElementById("home");
        if (x.style.display === "none") {
            x.style.display = "block";
            this.isShow = true;
        } else {
            x.style.display = "none";
            this.isShow = false;
        }
    }

}
