import { Component, OnInit } from '@angular/core';
import { UserService } from '../../shared/services/user.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {ToastrService} from 'ngx-toastr';
//import { TreeviewItem, TreeviewConfig } from 'ngx-treeview';
declare var $: any;

@Component({
  selector: 'app-business-type',
  templateUrl: './business-type.component.html',
  styleUrls: ['./business-type.component.css'],
  providers: [UserService]
})
export class BusinessTypeComponent implements OnInit {

    companyBasicInfoDetail: any = JSON.parse(localStorage.getItem('companyobj'));
    latestList: any;
    public selectedKeywords: any = [];
    objectKeys = Object.keys;
	public keywordsData: any = [];
	public businessTypes: any = [];
	public businessTypesChildValues: any = [];
	public businessTypesParentValues: any = {};
    public businessTypesAllValues: any = {};
    public busniessCode: any = [];
	public busniessCodeFirst: any = [];
	public busniessCodeSecond: any = [];
	public busniessCodeThird: any = [];
	public busniessCodeFourth: any = [];
	public busniessCodeFifth: any = [];
	public myOptions: any = [];
	public keywordsSelected: any = [];
    public selectedBusinessSubType: any = [];
    public selectedBusinessType: any = [];
	public selectedItem: any = '';
    public inputChanged: any = '';
    public searchValue: string = '';
	//
	 public firstVal: string = '';
	 public secondVal: string = '';
	 public thirdVal: string = '';
	 public fourthVal: string = '';
	 public fifthVal: string = '';
	checkBox = false;
    formValueArray = {};
	config2: any = {'placeholder': 'Select Keyword', 'sourceField': ['Keyword']};
    public loading = false;
    //add for multiselect

    constructor(private toastr: ToastrService, private userService: UserService, private route: ActivatedRoute, private router: Router) {

    }

    ngOnInit() {
        console.log('obj',this.companyBasicInfoDetail);
		this.loading = true;
		this.sicCode('{}', '1');

		this.userService.getBusinessType().subscribe(
			response =>{
				if(response.error) { 
                    this.toastr.error(`Server Error`);
				} else {
					let optns = [];
					response.forEach((opn, indx) => {
						let nOption = {};
						nOption['name'] = opn['name'];
						nOption['label'] = opn['label'];
						nOption['id'] = opn['id'];
						nOption['checked'] = false;
						let ChildOptns = [];
						opn['child'].forEach((child, ch) => {
							let cOption = {};
							cOption['name'] = child['name'];
							cOption['id'] = child['id'];
							cOption['checked'] = false;
							ChildOptns.push(cOption);
						});
						nOption['child'] = ChildOptns;
						optns.push(nOption);
					});
					this.businessTypes = optns;
					console.log(optns);
					this.loading = false;
				}
			},
			error =>{
                this.toastr.error(`Server Error`);
			}
		);		
    }

	//get sic code service function
	public sicCode(val, level){
		this.loading = true;
		this.userService.getSicCode(val).subscribe(
            response => {
				if(response.error) { 
                    this.toastr.error(`Server Error`);
				} else {
					console.log(response[0]);
					if(level === '1'){
                        this.busniessCodeFirst = response[0];
                      
						this.busniessCodeSecond = [];
						this.busniessCodeThird = [];
						this.busniessCodeFourth = [];
						this.busniessCodeFifth = [];
					}else if (level === '2'){
						this.busniessCodeSecond = response[0];
						this.busniessCodeThird = [];
						this.busniessCodeFourth = [];
						this.busniessCodeFifth = [];

					}
					else if (level === '3'){
						this.busniessCodeThird = response[0];
						this.busniessCodeFourth = [];
						this.busniessCodeFifth = [];

					}
					else if (level === '4'){
						this.busniessCodeFourth = response[0];
						this.busniessCodeFifth = [];

					}
					else if (level === '5'){
						this.busniessCodeFifth = response[0];

					}
					this.loading = false;
				}
			},
			error =>{
                this.toastr.error(`Server Error`);
			}
        );
    }
	selectedIndexFirst: number = null;
	selectedIndexSecond: number = null;
	selectedIndexThird: number = null;
	selectedIndexFourth: number = null;
	selectedIndexFifth: number = null;
    public onFirstLevelChanged(value, index) {
		this.selectedIndexFirst = index;
		this.selectedIndexSecond = null;
		this.selectedIndexThird = null;
		this.selectedIndexFourth = null;
		this.selectedIndexFifth = null;
		//subModule.active = !subModule.active; 
        this.busniessCode = value;
		this.firstVal = value;
        const val: any = { "section": value };
        const valId: any = { "Id": value };
        this.sicCode(val, '2');
        this.getKeywordData(valId);
		
    }

    public onSecondLevelChanged(value, index) {
		this.selectedIndexSecond = index;
		this.selectedIndexThird = null;
		this.selectedIndexFourth = null;
		this.selectedIndexFifth = null;
        this.busniessCode = value;
		this.secondVal = value;
        const val: any = { "section": this.firstVal, "division": value };
        const valId: any = { "Id": value };
        this.sicCode(val, '3');
        this.getKeywordData(valId);

		
    }

    public onThirdLevelChanged(value, index) {
		this.selectedIndexThird = index;
		this.selectedIndexFourth = null;
		this.selectedIndexFifth = null;
        this.busniessCode = value;
		this.thirdVal = value;
        const val: any = { "section": this.firstVal, "division": this.secondVal, "group": value };
        const valId: any = { "Id": value };
        this.sicCode(val, '4');
        this.getKeywordData(valId);
		
    }

    public onFourthLevelChanged(value, index) {
		this.selectedIndexFourth = index;
		this.selectedIndexFifth = null;
        this.busniessCode = value;
		this.fourthVal = value;
        const val: any = { "section": this.firstVal, "division": this.secondVal, "group": this.thirdVal, "mainClass": value };
        const valId: any = { "Id": value };
        this.sicCode(val, '5');
        this.getKeywordData(valId);
		
    }

    public onFifthLevelChanged(value, index) {
		//console.log(value);
		this.selectedIndexFifth = index;
        this.busniessCode = value.toString();
        const val: any = { "section": this.firstVal, "division": this.secondVal, "group": this.thirdVal, "mainClass": this.fourthVal, "subclass": value };	
        const valId: any = { "Id": value };
        this.getKeywordData(valId);

    }

	onSelectedChange(value: string){
		console.log('change:', value);
    }

	onFilterChange(value: string) {
        console.log('filter:', value);
    }

    clickKeyword(key, index) {

        this.selectedKeywords.push(key);
        this.keywordsData.splice(this.keywordsData.findIndex(item => item.Keyword === key.Keyword), 1);
    }

    removeKeyword(key, e) {
        this.latestList = JSON.parse(localStorage.getItem('currentObj'));
        this.selectedKeywords.splice(e, 1);
        for (const i of Object.keys(this.latestList)) {
            if (this.latestList[i].Keyword === key.Keyword) {
                this.keywordsData.push(key);
            }
        }  
    }

	//keyword get function
    public getKeywordData(obj) {
		this.loading = true;
		this.userService.keyWordService(obj).subscribe(
			response =>{
				if(response.error) { 
                    this.toastr.error(`Server Error`);
				} else {
                    console.log(response);
                    if (response.keywords.length > 0) {
                        this.keywordsData = response.keywords;
                        localStorage.setItem('currentObj', JSON.stringify(this.keywordsData));
                        this.searchValue = null;
                        this.loading = false;
                    }
                    else {
                        this.toastr.info(`There is no Keywords, Please Try Again`);
                        this.keywordsData = [];
                        this.searchValue = null;
                        this.loading = false;

                    }
                   
				}
			},
			error =>{
                this.toastr.error(`Server Error`);
			}
		);
    }

	//selected keyword list
	onSelect(item: any) {

		const temp: any = {"Id" : item.Id,"Keyword": item.Keyword};
		if(!item.HasChildren){
			const checkValExist: any = this.keywordsSelected.some(e => e.Id === item.Id);
			if(!checkValExist)
			this.keywordsSelected.push(temp);
		}else{
			this.keywordsSelected.push(temp);
			this.getKeywordData(temp);
			console.log(this.keywordsSelected);
		}	
    }
 
    onInputChangedEvent(val: string) {
	  this.searchValue = null;
	  console.log(val)
	}
	
	KeywordFun(name, id, isChild){
		
    }

	//keyword remove form selected list
	KeywordDelete(index){
		console.log(index);
		if(index!==0){
			let parentKeyword = this.keywordsSelected[index-1];
			this.keywordsSelected.splice(index,this.keywordsSelected.length-1);
			this.getKeywordData(parentKeyword);
		}else{
			this.keywordsSelected = [];
			this.getKeywordData({});
		}
		
    }

	//selected business type
	setSelected(businessTyle) {
		console.log(businessTyle);
		console.log(this.selectedBusinessSubType);
    }

	//business type parent select
    BusinessTypeParent(event) {
        this.businessTypesParentValues = event.target.value;
        console.log('click on', this.businessTypesParentValues);
		if(Object.keys(this.businessTypesAllValues).length !== 0){
			const exsitVal = this.businessTypesAllValues.parent;
			if(exsitVal !== event.target.values){
				this.businessTypesChildValues = [];
				this.businessTypes.map((pVal, i) => {
				if (pVal.name == exsitVal){
					 this.businessTypes[i].child.map((todo, i) => {
						todo.checked = false;
						
					 });
				  }
				});
			}
		}
    }

    //business type child select
    BusinessTypeChild(e, id, isChild, parentName) {
		if(Object.keys(this.businessTypesAllValues).length !== 0){
			const exsitVal = this.businessTypesAllValues.parent;
			if(exsitVal !== parentName){
				this.businessTypesChildValues = [];
				this.businessTypes.map((pVal, i) => {
				if (pVal.name == exsitVal){
					 this.businessTypes[i].child.map((todo, i) => {
						todo.checked = false;
					 });
				  }
				});
			}
        }

		(<HTMLInputElement>document.getElementById(parentName)).checked = true;
		if(Object.keys(this.businessTypesChildValues).length !== 0){
			if(this.businessTypesChildValues.length >= 2){
                this.businessTypes.map((pVal, i) => {
                    if (pVal.name == parentName) {
                        this.businessTypes[i].child.map((newC, i) => {
                            if (newC.name == e.target.value) {
                                e.target.checked = false;
                                newC.checked = false;
                            }
                        });
                    }
                });
                this.toastr.info(`You Can Select MAX 2 Sub Business Type`);
				return;
			}
		}
		if (e.target.checked) {
			this.businessTypesChildValues.push(e.target.value);
		}else{
			const childValue: any = this.businessTypesAllValues.child;
			for (const i of Object.keys(childValue)) {
				if (childValue[i] === e.target.value) {
					childValue.splice(i, 1);
					break;
				}
			}
			this.businessTypesChildValues = childValue;
		}
		this.businessTypesAllValues = {'parent':parentName, child: this.businessTypesChildValues};
		this.businessTypesParentValues = parentName;
		console.log(this.businessTypesParentValues);
		console.log(this.businessTypesChildValues);
    }


	//submit request
    businessTypeSubmit() {
		//console.log(this.busniessCode.length);
	  //  if(Object.keys(this.busniessCode).length === 0){
		//    //console.log('11');
		// 	this.toastr.error("Please Select Business code (SIC Code)", `Error`);
		// 	return;
		// }
		//company id
		let CompanyId = this.route.snapshot.queryParams["CompanyId"];

		//keyword 6 digits code
        let last: any = this.selectedKeywords[this.selectedKeywords.length-1];
		// if(!last){
		// 	this.toastr.error("Please Select Keyword", `Error`);
		// 	return;
		// }
		// if(Object.keys(this.businessTypesParentValues).length === 0){
		// 	this.toastr.error("Please Select Business type", `Error`);
		// 	return;
		// }
		// if(Object.keys(this.businessTypesChildValues).length === 0){
		// 	this.toastr.error("Please Select Sub Business type", `Error`);
		// 	return;
		// }
		if(!this.companyBasicInfoDetail['BasicCompanyInfo']['taxonomyId']){
			this.toastr.error("Please enter Taxonomy Id", `Error`);
			return;
		}

		//keywords
		let keywordsSubmit = [];
        for (let entry of this.selectedKeywords) {
			keywordsSubmit.push(entry.Keyword);
		}		

        if (this.companyBasicInfoDetail.BasicCompanyInfo.hasOwnProperty("BusinessType")) {
            this.companyBasicInfoDetail.BasicCompanyInfo.BusinessType = this.businessTypesParentValues;
        } 
        else {
            this.companyBasicInfoDetail['BasicCompanyInfo']['BusinessType'] = '';
        }  
        this.companyBasicInfoDetail['BasicCompanyInfo']['Keywords'] = keywordsSubmit;
        this.companyBasicInfoDetail['BasicCompanyInfo']['SubBusinessType'] = this.businessTypesChildValues;
        // this.companyBasicInfoDetail['BasicCompanyInfo']['taxonomyId'] = this.busniessCode;
        console.log('after update in businessType screen', this.companyBasicInfoDetail);

				localStorage.setItem('companyobj', JSON.stringify(this.companyBasicInfoDetail));
				console.log(this.companyBasicInfoDetail)
        this.router.navigate(["/pages/admin-profile"], { queryParams: { CompanyId: CompanyId } });

    }

}
