import { Component, OnInit, Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from '../../shared/services/user.service';
import Swal from 'sweetalert2';
import * as $ from 'jquery';
import { ViewChild, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { OtherOfficeLocationComponent } from './other-office-location/other-office-location.component';

 
@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'app-basic-entity-information',
  templateUrl: './basic-entity-information.component.html',
  styleUrls: ['./basic-entity-information.component.css']
})
export class BasicEntityInformationComponent implements OnInit {

    @ViewChild('parent', { read: ViewContainerRef }) container: ViewContainerRef;
    LoggedInUserEmail: any = localStorage.getItem('email');
    LoggedInUserName: any = localStorage.getItem('username');
    LoggedInUserCountry: any = localStorage.getItem('CountryRegion');
    LoggedInUserCity: any = localStorage.getItem('City');
    LoggedInUserStreet: any = localStorage.getItem('Street');
    LoggedInUserZip: any = localStorage.getItem('ZipPostal');
    LoggedInUserMobile: any = localStorage.getItem('mobile');

    basicEntityInformationForm: FormGroup;
    public Companydetail: any = [];
    companyBasicInfoDetail: any = JSON.parse(localStorage.getItem('companyobj'));
    objectKeys = Object.keys;
    public countryListing: any = [];
    public yearListing: any = [];
    public statesListing: any = [];
    public selectedCountry: any = '';
    public selectedState: any = '';
    public companyUpdatedobj: any = '';
    isShow: any = false;
    public DynamicOtherOfficeLocationListing: any = [];
	OfficeLocations: any = []; 
	countChildComp: any = 1;

    constructor(private formBuilder: FormBuilder, private _cfr: ComponentFactoryResolver, private route: ActivatedRoute, private userService: UserService, private router: Router) {

        this.getformData();
    }

    ngOnInit() {
		localStorage.setItem('locationVariable', "");
        console.log('local storage object of company before any update',this.companyBasicInfoDetail.OfficeLocations);
		this.OfficeLocations = this.companyBasicInfoDetail.OfficeLocations;
		if(this.OfficeLocations && Object.keys(this.OfficeLocations).length > 0){
			this.isShow = true;
			for (let entry of this.OfficeLocations) {
				setTimeout(()=>{ this.AddNewLocation(entry); }, 1000);
			}
        }
        this.userService.getYears().subscribe((data) => {
            this.yearListing = data;
        }, (error) => {
            console.log("err", error);
            }); 

        this.userService.getCountry().subscribe((data) => {
            this.countryListing = data;
        }, (error) => {
            console.log("err", error);
            }); 

        this.basicEntityInformationForm = this.formBuilder.group({
            companyNameBasic: [this.companyBasicInfoDetail.BasicCompanyInfo.CompanyName, Validators.required],
            yearFoundedBasic: [this.companyBasicInfoDetail.BasicCompanyInfo.YearCompanyFounded, Validators.required],
            registrationNumberBasic: [this.companyBasicInfoDetail.BasicCompanyInfo.RegistrationNumber, Validators.required],
            websiteUrlBasic: [this.companyBasicInfoDetail.BasicCompanyInfo.CompanyWebsiteUrl, Validators.required],
            nosOfEmployeesBasic: [this.companyBasicInfoDetail.BasicCompanyInfo.TotalNoEmployees, Validators.required],
            gbtLinkBasic: [this.companyBasicInfoDetail.BasicCompanyInfo.GBTAccreditedLink, Validators.required],
            licensingBasic: [this.companyBasicInfoDetail.BasicCompanyInfo.CertificationAndLicesing, Validators.required],
            headOfficeCity: [this.companyBasicInfoDetail.BasicCompanyInfo.CompanyHeadQuarters.City, Validators.required],
            headOfficeState: [this.companyBasicInfoDetail.BasicCompanyInfo.CompanyHeadQuarters.State, Validators.required],
            headOfficeStreet: [this.companyBasicInfoDetail.BasicCompanyInfo.CompanyHeadQuarters.Street, Validators.required],
            headOfficeCountry: [this.companyBasicInfoDetail.BasicCompanyInfo.CompanyHeadQuarters.Country, Validators.required],
            OfficeLocations: [this.companyBasicInfoDetail.BasicCompanyInfo.OfficeLocations, Validators.required],
            //otherOfficeCity: [this.companyBasicInfoDetail.BasicCompanyInfo.CompanyHeadQuarters.City, Validators.required],
            //otherOfficeState: [this.companyBasicInfoDetail.BasicCompanyInfo.CompanyHeadQuarters.State, Validators.required],
            //otherOfficeStreet: [this.companyBasicInfoDetail.BasicCompanyInfo.CompanyHeadQuarters.Street, Validators.required],
        });

        const formValue: any = this.basicEntityInformationForm.value;

        if (formValue.headOfficeCountry) {
            this.userService.getStates(formValue.headOfficeCountry).subscribe((data) => {
                if (JSON.stringify(data) === '{}') {
                    Swal("", "States Not Found!", "error")
                }
                this.statesListing = data;
                console.log('checking states', data);
            }, (error) => {
                //console.log("err", error);
            });
        }
    }

    onCountryChange(event) {
        this.selectedCountry = (<HTMLInputElement>document.getElementById('countryId')).value;
		console.log(this.selectedCountry);
        this.userService.getStates(this.selectedCountry).subscribe((data) => {
            if (JSON.stringify(data) === '{}') {
                Swal("", "States Not Found!", "error")
            }
            this.statesListing = data;
        }, (error) => {
            console.log("err", error);
        });
    }

    onStateChange() {
        this.selectedState = (<HTMLInputElement>document.getElementById('stateId')).value;
    }

    public getformData() {
        this.basicEntityInformationForm = this.formBuilder.group({
            companyNameBasic: ['', Validators.required],
            yearFoundedBasic: ['', Validators.required],
            registrationNumberBasic: ['', Validators.required],
            websiteUrlBasic: ['', Validators.required],
            nosOfEmployeesBasic: ['', Validators.required],
            gbtLinkBasic: ['', Validators.required],
            licensingBasic: ['', Validators.required],
            headOfficeCity: ['', Validators.required],
            headOfficeState: ['', Validators.required],
            headOfficeStreet: ['', Validators.required],
            headOfficeCountry: ['', Validators.required],
        });
    }

    //testobj() {
    //    alert('call blur function');
    //    console.log('object input', this.companyBasicInfoDetail.BasicCompanyInfo.CompanyName);
    //}

    onBasicEntityInformationSubmit() {
        const formValue = this.basicEntityInformationForm.value;
		let newOfficeLocations: any = JSON.parse(localStorage.getItem('locationVariable'));
		console.log(newOfficeLocations);
		newOfficeLocations.forEach(obj=>{
			let keys = Object.keys(obj);
			keys.forEach(key=>{
				if(obj[key] === '' || obj[key] === undefined || key === 'id')
					delete obj[key];
			});
		});
		this.companyBasicInfoDetail.OfficeLocations = newOfficeLocations;
        localStorage.setItem('companyobj', JSON.stringify(this.companyBasicInfoDetail));  
        this.router.navigate(["/pages/business-type"], { queryParams: { CompanyId: this.companyBasicInfoDetail.CompanyID }});
    }

    onRoleChange(value) {
        
        if (value === 'Yes') {
            this.isShow = true;
        }
        else {
			localStorage.setItem('locationVariable', "");
            this.isShow = false;
        }
        
    }
    AddNewLocation(data) {
		if(data)
		data['id'] = this.countChildComp;
	
        var comp  = this._cfr.resolveComponentFactory(OtherOfficeLocationComponent);
        var expComponent = this.container.createComponent(comp);
		expComponent.instance.data = data;
		expComponent.instance.count = this.countChildComp;
        expComponent.instance._ref = expComponent;
		this.countChildComp++;
    }

}
