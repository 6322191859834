//import {Observable, of} from 'rxjs';
import {HttpHeaders} from '@angular/common/http';

export class ApiModel {
  public token: string;
  public host: string;
  public liveHost: string;
  public httpOptions: any ;
  public companyUrl: any;
  public userUrl: any;
  public tokBoxSessionUrl: any;
  public baseUrlSearch: any;
  public baseUrlElasticSearch: any;
  public newsFeed: any;
  public baseUrlBackOffoice: any;

  constructor() {
    this.companyUrl = 'https://xhtaovbv84.execute-api.us-east-1.amazonaws.com/Prod/';
    this.userUrl = 'https://cejpj04owc.execute-api.us-east-1.amazonaws.com/Prod/'
    this.tokBoxSessionUrl = 'https://rx3ejm6q4g.execute-api.us-east-1.amazonaws.com/Prod/';
    this.baseUrlSearch = 'https://3qs5yubxpg.execute-api.us-east-1.amazonaws.com/Prod/';
    this.baseUrlElasticSearch = 'https://fgs9zqxu2a.execute-api.us-east-1.amazonaws.com/Prod/';
    this.newsFeed = 'https://v2j2y4ggff.execute-api.us-east-1.amazonaws.com/Prod/rss';
	this.baseUrlBackOffoice = 'https://6x1rft6wre.execute-api.us-east-1.amazonaws.com/Prod/';
	//local path for services
     //this.companyUrl = 'http://192.168.9.16:4000/';
      //this.userUrl = 'http://192.168.9.16:5000/';
      //this.baseUrlSearch = 'http://192.168.9.70:8000/';
      //this.baseUrlBackOffoice = 'http://192.168.9.16:8000/';
    this.token = 'Bearer ' + localStorage.getItem('token');
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Authorization': this.token
      })
    };
  }
}

