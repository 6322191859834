import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { LoadingModule } from 'ngx-loading';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { UserService } from './shared/services/user.service';
import * as $ from 'jquery';
import { RoutingModule } from './routing.module';
import {Ng2PaginationModule} from 'ng2-pagination';
//import { AutocompleteModule } from 'ng2-input-autocomplete';


import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LeftNevComponent } from './pages/common/left-nev/left-nev.component';
import { TopNevComponent } from './pages/common/top-nev/top-nev.component';
import { BusinessTypeComponent } from './pages/business-type/business-type.component';
import { AdminProfileComponent } from './pages/admin-profile/admin-profile.component';
import { BasicEntityInformationComponent } from './pages/basic-entity-information/basic-entity-information.component';
import { OtherOfficeLocationComponent } from './pages/basic-entity-information/other-office-location/other-office-location.component';
import { LaunchDateComponent } from './pages/launch-date/launch-date.component';



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
	DashboardComponent,
	TopNevComponent,
	LeftNevComponent,
    BusinessTypeComponent,
    AdminProfileComponent,
    BasicEntityInformationComponent,
    OtherOfficeLocationComponent,
	LaunchDateComponent
  ],
  imports: [
    BrowserModule,
	HttpModule,
    HttpClientModule,
	FormsModule,
	ReactiveFormsModule,
	BrowserAnimationsModule,
	RoutingModule,
	Ng2PaginationModule,
	//AutocompleteModule.forRoot(),
	ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
	SweetAlert2Module.forRoot({
	  buttonsStyling: false,
	  customClass: 'modal-content',
	  confirmButtonClass: 'btn btn-primary',
	  cancelButtonClass: 'btn'
	}),
	LoadingModule,
  ],
  entryComponents: [OtherOfficeLocationComponent],
  providers: [
	UserService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
