import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from '../../shared/services/user.service';
import Swal from 'sweetalert2';
import { ApiModel } from '../../shared/models/Api.model';
import * as $ from 'jquery';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.css']
})
export class AdminProfileComponent implements OnInit {
    AdminProfileForm: FormGroup;
    apiModel: ApiModel = new ApiModel();
    public companyBasicInfoDetail: any = JSON.parse(localStorage.getItem('companyobj'));
    role: any = localStorage.getItem('role');
    public countryListing: any = [];
    objectKeys = Object.keys;
    selectedFile: File = null;  
    localUrl: any = [];
    roleArray: any = [];
    imageUrl: any = '';
    submitted = false;
    public loading = false;

    constructor(public http: HttpClient, private toastr: ToastrService, private userService: UserService, private sanitizer: DomSanitizer, private router: Router, private formBuilder: FormBuilder, private route: ActivatedRoute) {
        //this.getformData();
    }

    ngOnInit() {
       
        if (this.companyBasicInfoDetail['AdminInfo'].hasOwnProperty("profileImageUrl")) {
            if (this.companyBasicInfoDetail.AdminInfo.profileImageUrl === '') { 
                this.localUrl = 'assets/img/tbsImg/download1.png';
            }
            else {
                //this.localUrl = this.sanitizer.bypassSecurityTrustUrl(this.companyBasicInfoDetail.AdminInfo.profileImageUrl);
                this.localUrl = this.companyBasicInfoDetail.AdminInfo.profileImageUrl;
            }
        }
        //else {
        //    this.companyBasicInfoDetail['AdminInfo']['profileImageUrl'] = '';
        //}

       // let arrayOfRole = this.role.split(",");

        this.userService.getCountry().subscribe((data) => {
            this.countryListing = data;
        }, (error) => {
            console.log("err", error);
        }); 
       
        //this.companyBasicInfoDetail['AdminInfo']['profileImageUrl'] = '';
        this.AdminProfileForm = this.formBuilder.group({
            adminEmail: [this.companyBasicInfoDetail.AdminInfo.Email, Validators.required],
            adminLocation: [this.companyBasicInfoDetail.AdminInfo.Location, Validators.required],
            adminFirstName: [this.companyBasicInfoDetail.AdminInfo.FirstName, Validators.required],
            adminLastName: [this.companyBasicInfoDetail.AdminInfo.LastName, Validators.required],
            adminJobTitle: [this.companyBasicInfoDetail.AdminInfo.JobTitle, Validators.required],
            adminRole: ['Administrators', Validators.required],
            adminPhone: [this.companyBasicInfoDetail.AdminInfo.Mobile, Validators.required],
            profileImageUrl: [this.companyBasicInfoDetail.AdminInfo.profileImageUrl, Validators.required],
        });   
    }

    get f() { return this.AdminProfileForm.controls; }

    adminProfileSubmit() {
        this.loading = true;
        this.submitted = true;
        if (this.AdminProfileForm.invalid) {
            return;
        }
            
            this.companyBasicInfoDetail['AdminInfo']['profileImageUrl'] = this.imageUrl;
            this.companyBasicInfoDetail['verifiedBy'] = this.companyBasicInfoDetail.AdminInfo.FirstName;
            this.companyBasicInfoDetail['Ispromoted'] = 'Promoted';
            console.log('after pushing field', this.companyBasicInfoDetail);

            this.userService.businessTypeSubmit(this.companyBasicInfoDetail).subscribe(
                response => {
                    if (response.error) {
                        this.loading = false;
                        this.toastr.error(`Profile Updated Failed`);
                    } else {
                        if (response) {
                            this.loading = false;
                            this.toastr.success('Profile Updated Successfully & Promote to TBS');
                        }
                        localStorage.setItem('companyobj', JSON.stringify(this.companyBasicInfoDetail));
                        this.router.navigate(["/dashboard"]);
                        console.log('final obj post call', this.companyBasicInfoDetail);
                    }
                },
                error => {
                    this.loading = false;
                    this.toastr.error(`Profile Updated Failed`);
                });
      
    }

    onFileSelected(event) {
        this.selectedFile = <File>event.target.files[0];
        var reader = new FileReader();
        var encodedImage = "";
        reader.onloadend = (ev) => {
            this.localUrl = reader.result;
            encodedImage = reader.result.split(",")[1];
            this.onUpload(encodedImage);
        }
        reader.readAsDataURL(event.target.files[0]);
    }

    onUpload(encodedImage) {
        var imgData = {};
        imgData['avatar'] = encodedImage;
        imgData['filename'] = this.selectedFile.name;
        this.http.post(this.apiModel.userUrl + 'upload_profile_avatar', imgData)
            .subscribe((res: any) => {
                console.log('status of image upload', res);
                this.toastr.success('Profile Image Uploaded Successfully');
                console.log('checking the image path', res.data);
                this.imageUrl = res.data;
               
                //this.AdminProfileForm.controls['profileImageUrl'].setValue(res.data);
            });
    }
}
