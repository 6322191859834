import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from '../../shared/services/user.service';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';

@Component({
    selector: 'app-launch-date',
    templateUrl: './launch-date.component.html',
    styleUrls: ['./launch-date.component.css'],
    providers: [UserService]
})
export class LaunchDateComponent implements OnInit {
    title = 'GBT';
    LoggedInUserEmail: any = localStorage.getItem('email');
    LoggedInUserName: any = localStorage.getItem('username');
    public companiesData: any = [];
    public statesListing: any = [];
    public taxonomyListing: any = [];
    public countryListing: any = [];
    public busniessCode: any = [];
    totalItem: Number = 0;
    objectKeys = Object.keys;
    public selectedCountry: any = '';
    public selectedState: any = '';
    public selectedCity: any = '';
    public selectedCompanyName: any = '';
    public selectedOwner: any = '';
    public selectedTaxonomy: any = '';
    public jsonObj: any = {};
    public loading = false;

    columnDefs = [
        { headerName: 'Make', field: 'make' },
        { headerName: 'Model', field: 'model' },
        { headerName: 'Price', field: 'price' }
    ];

    rowData = [
        { make: 'Toyota', model: 'Celica', price: 35000 },
        { make: 'Ford', model: 'Mondeo', price: 32000 },
        { make: 'Porsche', model: 'Boxter', price: 72000 }
    ];

    constructor(private toastr: ToastrService, private userService: UserService, private route: ActivatedRoute, private router: Router, ) {

    }

    ngOnInit() {
        //this.getServerData();
        let email = this.route.snapshot.queryParams["email"];
    }

    launchDate: any = '';

    onSubmit() {
        this.loading = true;
        this.userService.launchDateService().subscribe((data) => {
            // console.log(data);
            // this.launchDate = data.data;
            this.loading = false;
            this.toastr.success(`Launch Date Downdated Successfully !!`, 'SUCCESS');
        }, (error) => {
            this.toastr.error(error.error.error, `Error`);
        });
    }

    public getServerData() {
        this.loading = true;
        this.userService.getCountry().subscribe((data) => {
            this.countryListing = data;
        }, (error) => {
            this.toastr.error(error.error.error, `Error`);
        });

        // this.userService.launchDateService().subscribe((data) => {
        //     console.log(data);
        // }, (error) => {
        //     this.toastr.error(error.error.error, `Error`);
        // });

        //getTaxonomy
        this.userService.getTaxonomy().subscribe((data) => {
            this.taxonomyListing = data;
            console.log('check taxonomy listing', this.taxonomyListing);
        }, (error) => {
            this.toastr.error(error.error.error, `Error`);
        });

        this.userService.getSicCode('{}').subscribe((data) => {
            this.busniessCode = data;
        }, (error) => {
            this.toastr.error(error.error.error, `Error`);
        });
        this.userService.getCompanies().subscribe(
            response => {
                if (response.error) {
                    this.toastr.error('Server Error', `Error`);
                } else {
                    this.companiesData = response.data;
                    console.log('Before filter', this.companiesData);
                    this.totalItem = response.data.length;
                }
                this.loading = false;
            },
            error => {
                this.toastr.error('Server Error', `Error`);
            });
    }

    companyDetails(obj, companyId) {
        localStorage.setItem('companyobj', JSON.stringify(obj));
        //this.router.navigate(["/pages/business-type"],{queryParams:{CompanyId:companyId}});
        this.router.navigate(["/pages/basic-entity-information"], { queryParams: { CompanyId: companyId } });
    }

    onCountryChange() {
        this.selectedCountry = (<HTMLInputElement>document.getElementById('countryId')).value;
        this.userService.getStates(this.selectedCountry).subscribe((data) => {
            if (JSON.stringify(data) === '{}') {
                Swal("", "States Not Found!", "error")
            }
            this.statesListing = data;
            console.log('checking states', data);
        }, (error) => {
            this.toastr.error(error.error.error, `Error`);
        });
    }

    onStateChange() {
        this.selectedState = (<HTMLInputElement>document.getElementById('stateId')).value;
    }

    onBlurCity() {
        this.selectedCity = (<HTMLInputElement>document.getElementById('city')).value;
    }

    onBlurCompanyName() {
        this.selectedCompanyName = (<HTMLInputElement>document.getElementById('companyName')).value;
    }

    onBlurOwner() {
        this.selectedOwner = (<HTMLInputElement>document.getElementById('owner')).value;
    }

    onTaxonomyChange() {
        this.selectedTaxonomy = (<HTMLInputElement>document.getElementById('taxonomy')).value;
    }


    searchFilter() {
        this.jsonObj = { 'BasicCompanyInfo.CompanyHeadQuarters.Country': this.selectedCountry, 'BasicCompanyInfo.CompanyHeadQuarters.State': this.selectedState, 'BasicCompanyInfo.CompanyHeadQuarters.City': this.selectedCity, 'BasicCompanyInfo.CompanyName': this.selectedCompanyName, 'AdminInfo.FirstName': this.selectedOwner, 'BasicCompanyInfo.taxonomyId': this.selectedTaxonomy }

        if (!this.selectedCountry && !this.selectedState && !this.selectedCity && !this.selectedCompanyName && !this.selectedOwner && !this.selectedTaxonomy) {
            Swal("", "Select any above input First!", "error")
        }
        else {
            this.loading = true;
            this.userService.getFilterCompanies(this.jsonObj).subscribe((data) => {
                this.loading = false;
                this.companiesData = data.data;
                console.log('after search', data);
            }, (error) => {
                this.loading = false;
                this.toastr.error(error.error.error, `Error`);
            });
        }
    }

    clear() {
        (<HTMLInputElement>document.getElementById('countryId')).value = "";
        (<HTMLInputElement>document.getElementById('stateId')).value = "";
        (<HTMLInputElement>document.getElementById('city')).value = "";
        (<HTMLInputElement>document.getElementById('companyName')).value = "";
        (<HTMLInputElement>document.getElementById('owner')).value = "";
        (<HTMLInputElement>document.getElementById('taxonomy')).value = "";
        this.selectedCountry = "";
        this.selectedState = "";
        this.selectedCity = "";
        this.selectedCompanyName = "";
        this.selectedOwner = "";
        this.selectedTaxonomy = "";
        this.getServerData();
    }
}
