import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-top-nev',
  templateUrl: './top-nev.component.html',
  styleUrls: ['./top-nev.component.css']
})
export class TopNevComponent implements OnInit {

    profileImage: any = localStorage.getItem('profileImage');
    public isProfilePhoto: any;

    constructor(private router: Router, private toastr: ToastrService) { }

    ngOnInit() {
       
    }
    logout() {
        localStorage.setItem('email', "");
        localStorage.setItem('username', "");
        this.router.navigate(["/login"]);
        this.toastr.success('Logout successsfully', `Logout: successs`);
    }



}
